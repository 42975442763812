<template>
  <validation-observer ref="detailsRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Listing Details</th>
          </tr>
        </thead>
      </table>

      <b-col cols="12" md="6">
        <b-form-group label="Title*" label-for="title">
          <b-form-input id="title" v-model="vehicleData.title" ref="title" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="1" class="text-center">
        <b-form-group v-if="vehicleData.model" label="Creator" label-for="title">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="tittleCreate()" variant="relief-dark" block class="btn-icon">
            <feather-icon icon="ChevronsLeftIcon" />
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Listing Price*" label-for="listingPrice">
          <validation-provider #default="{ errors }" name="Listing Price" :rules="wherefrom == 'inventory-edit-sale' ? 'required' : ''">
            <cleave id="listingPrice" v-model="vehicleData.listingPrice" class="form-control" :raw="true" :options="options.number" />
            <small class="text-danger" v-if="vehicleData.listingPrice == null && wherefrom == 'inventory-edit-sale'">Listing Price is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="1" class="text-center">
        <b-form-group label="History" label-for="history">
          <b-button id="price-history-button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="showTooltip" variant="outline-primary" block class="btn-icon">
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-popover target="price-history-button" placement="right" triggers="click">
            <template #title>
              Listing Price History
            </template>
            <table v-if="vehicleData.listingPriceHistory.length > 0">
              <thead>
                <th style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">#</th>
                <th style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">Price</th>
                <th style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">Before Price</th>
                <th style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">Rate Of Change</th>
                <th style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">Time</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in vehicleData.listingPriceHistory" :key="index" :style="index == 0 ? 'background-color:#f3f2f7' : ''">
                  <th style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">{{ index + 1 }}</th>
                  <td style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">{{ formatPrice(item.price, 2) }}$</td>
                  <td style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">{{ formatPrice(item.beforePrice, 2) }}$</td>
                  <td v-if="item.rateOf > 0 || item.rateOf < 0" style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">
                    <b :style="item.rateOf > 0 ? 'color:green' : 'color:red'">{{ item.rateOf }}%</b>
                  </td>
                  <td v-else style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">
                    <b>{{ item.rateOf }}%</b>
                  </td>
                  <td style="text-align: center;border:1px solid black;font-size: 0.8rem;padding: 0.5rem;">{{ dateFormat(item.time) }}</td>
                </tr>
              </tbody>
            </table>
            <h6 style="text-align: center;" v-else>No data found</h6>
          </b-popover>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12">
        <label for="textarea-default">Description</label>
        <b-form-textarea id="textarea-default" v-model="vehicleData.description" placeholder="Description" style="white-space: pre-wrap" rows="10" />
      </b-col>

      <b-col cols="12" md="12" class="text-center mt-1">
        <b-form-group v-if="vehicleData.model" label="Generate MSRP Configuration and Accessories" label-for="title">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="tittleDescription()" variant="relief-dark" block class="btn-icon">
            <feather-icon icon="ChevronsUpIcon" />
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-checkbox checked="false" class="mt-2" v-model="vehicleData.homepagestatus" name="check-button" switch inline>
          Homepage Featured Listing
        </b-form-checkbox>
      </b-col>

      <b-col cols="4">
        <b-form-checkbox checked="false" class="mt-2" v-model="vehicleData.collectionListing" name="check-button" switch inline>
          Collection Listing
        </b-form-checkbox>
      </b-col>

      <b-col cols="4">
        <b-form-checkbox checked="false" class="mt-2" v-model="vehicleData.leaseListing" name="check-button" switch inline>
          Lease Listing
        </b-form-checkbox>
      </b-col>

      <b-col md="12" class="mt-2">
        <b-card title="Selecting a Featured Photo">
          <b-row>
            <b-col lg="2" md="3" sm="2" cols="4" v-for="(item, index) in vehicleData.inventoryGallery" :key="index" class="mb-1">
              <b-img :src="baseURL + item.name" v-if="item != null" thumbnail @click="selectImage(item.name)" :style="[item.name == vehicleData.selectedImage ? 'border: 3px solid green' : '']" fluid />
            </b-col>
            <h4 v-if="vehicleData.inventoryGallery.length == 0" class="ml-2">Upload an image from the listing photos area</h4>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { BImg, BCard, BFormGroup, BFormInput, BCardBody, BFormCheckbox, BRow, BFormTextarea, BCol, BFormFile, BContainer, BMediaAside, BButton, BOverlay, BPopover, VBTooltip } from 'bootstrap-vue';
import store from '@/store';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import Ripple from 'vue-ripple-directive';
import { isObject } from '@vueuse/shared';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import router from '@/router';
import moment from 'moment';

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BContainer,
    BMediaAside,
    BCard,
    BCardBody,
    BFormTextarea,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BOverlay,
    BPopover,

    VBTooltip,

    //validation
    ValidationProvider,
    ValidationObserver,

    ToastificationContent,
    BCardActions,
    Cleave,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },

    dropAll: {
      required: true,
    },
  },

  data() {
    return {
      tempImage: null,
      baseURL: store.state.app.baseURL,
      croppedImage: null,
      wherefrom: router.currentRoute.name,
      tempTotal: 0,
      formShow: false,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {},

  computed: {},

  methods: {
    showTooltip() {
      this.$root.$emit('bv::show::tooltip', 'price-history-button');
    },

    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD-MM-YYYY HH:mm');
      }
    },

    tittleCreate() {
      this.vehicleData.title = '';

      this.dropAll.modelYear.forEach((element) => {
        if (element.id == this.vehicleData.modelYear) {
          this.vehicleData.title += element.value;
        }
      });

      this.dropAll.make.forEach((element) => {
        if (element.id == this.vehicleData.make) {
          this.vehicleData.title += ' ' + element.value;
        }
      });

      this.dropAll.model.forEach((element) => {
        if (element.id == this.vehicleData.model) {
          this.vehicleData.title += ' ' + element.value;
        }
      });
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    tittleDescription() {
      this.tempTotal = 0;

      if (this.vehicleData.msrpOptions.length > 0) {
        this.vehicleData.description = '';
        this.vehicleData.msrpOptions.forEach((element) => {
          this.vehicleData.description += element.title + ' : ' + this.formatPrice(element.price) + '\n';
          this.tempTotal += element.price;
        });

        if (this.vehicleData.baseMsrp > 0) {
          this.vehicleData.description += 'Total MSRP: ' + this.formatPrice(this.tempTotal + Number(this.vehicleData.baseMsrp));
        }
      } else {
        this.vehicleData.description = '';
      }

      if (this.vehicleData.accessioreOptions.length > 0) {
        if (this.vehicleData.msrpOptions.length > 0) {
          this.vehicleData.description += '\n';
        }

        this.vehicleData.accessioreOptions.forEach((element) => {
          this.vehicleData.description += element.title + ' - ' + element.quantity + '\n';
        });
      }
    },

    selectImage(val) {
      this.vehicleData.selectedImage = val;
    },
  },
};
</script>

<style>
.popover .popover-body {
  overflow: auto !important;
}
.popover {
  max-width: 450px !important;
}
</style>
