<template>
  <validation-observer ref="vehicleRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Description of Vehicle</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <!-- :before-change="validationForm1" -->
    <b-row class="mt-2">
      <b-col cols="12" md="8">
        <b-form-group label="Vehicle Identification Number*" label-for="Vehicle Identification Number">
          <validation-provider #default="{ errors }" name="Vehicle Identification Number" rules="required">
            <b-form-input id="vinNo" @change="vinNoCheck(vehicleData.vinNo)" :disabled="vehicleData.purchaseQuoteId > 0 || vehicleData.editable == 'no'" v-uppercase v-model="vehicleData.vinNo" type="text" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Stock Number" label-for="Stock Number">
          <b-form-input id="stockNumber" disabled v-model="vehicleData.stockNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Condition*" label-for="condition">
          <validation-provider #default="{ errors }" name="Condition" rules="required">
            <v-select v-model="vehicleData.condition" :options="dropCondition" :disabled="vehicleData.editable == 'no'" :state="errors.length > 0 ? false : null" label="title" :clearable="false"> </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Mileage Unit*" label-for="mileageUnit">
          <validation-provider #default="{ errors }" name="Mileage Unit" :rules="wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-inception' ? 'required' : ''">
            <v-select v-model="vehicleData.mileageUnit" :options="dropMileageUnit" :disabled="vehicleData.editable == 'no'" :clearable="false" label="title"> </v-select>
            <small class="text-danger" v-if="vehicleData.mileageUnit == null && (wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-inception')">The Mileage Unit field is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Exterior Colour -->
      <b-col cols="12" md="4">
        <b-form-group label="Exterior Colour*" label-for="extColour">
          <validation-provider #default="{ errors }" name="Exterior Colour" :rules="wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-inception' ? 'required' : ''">
            <v-select
              v-model="vehicleData.extColour"
              :disabled="vehicleData.editable == 'no'"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'extColour' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :value="vehicleData.extColour"
              :reduce="(val) => val.id"
              :options="dropAll['extColour']"
              label="value"
              :clearable="true"
            >
            </v-select>
            <small class="text-danger" v-if="vehicleData.extColour == null && (wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-inception')">The Exterior Colour field is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Year -->
      <b-col cols="12" md="4">
        <b-form-group label="Model Year*" label-for="year">
          <validation-provider #default="{ errors }" name="Model Year" rules="required">
            <v-select
              v-model="vehicleData.modelYear"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'modelYear' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :reduce="(val) => val.id"
              :options="dropAll['modelYear']"
              label="value"
              :clearable="true"
              :disabled="oldCardDisabled || vehicleData.editable == 'no'"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Mileage*" label-for="mileage">
          <validation-provider #default="{ errors }" name="Mileage" :rules="wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-inception' ? 'required' : ''">
            <b-form-input id="mileage" :disabled="vehicleData.editable == 'no'" v-model="vehicleData.mileageValue" type="number" />
            <small class="text-danger" v-if="vehicleData.mileageValue == null && (wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-inception')">The Mileage field is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Int Colour -->
      <b-col cols="12" md="4">
        <b-form-group label="Interior Colour*" label-for="intColour">
          <validation-provider #default="{ errors }" name="Interior Colour" :rules="wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-inception' ? 'required' : ''">
            <v-select
              v-model="vehicleData.intColour"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'intColour' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :value="vehicleData.intColour"
              :reduce="(val) => val.id"
              :options="dropAll['intColour']"
              label="value"
              :clearable="true"
              :disabled="vehicleData.editable == 'no'"
            >
            </v-select>
            <small class="text-danger" v-if="vehicleData.intColour == null && (wherefrom == 'inventory-edit-sale' || wherefrom == 'inventory-edit-purchase' || wherefrom == 'inventory-edit-inception')">The Interior Colour field is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Make -->
      <b-col cols="12" md="4">
        <b-form-group label="Make*" label-for="make">
          <validation-provider #default="{ errors }" name="Make" rules="required">
            <v-select
              v-model="vehicleData.make"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'make' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :value="vehicleData.make"
              :state="errors.length > 0 ? false : null"
              :options="dropAll['make']"
              :reduce="(val) => val.id"
              label="value"
              @input="modelClear()"
              :clearable="true"
              :disabled="oldCardDisabled || vehicleData.editable == 'no'"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Model -->
      <b-col cols="12" md="4">
        <b-form-group label="Model*" label-for="model">
          <validation-provider #default="{ errors }" name="Model" rules="required">
            <v-select
              v-model="vehicleData.model"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'model' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :value="vehicleData.model"
              :state="errors.length > 0 ? false : null"
              :options="modelOptionsVal"
              :reduce="(val) => val.id"
              label="value"
              :clearable="true"
              @input="trimClear()"
              :disabled="!vehicleData.make > 0 || oldCardDisabled || vehicleData.editable == 'no'"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="12" md="4">
        <b-form-group label="Seating Capacity" label-for="seatingCapacity">
          <vue-numeric-input v-model="vehicleData.seatingCapacity" :min="0" controlsType="plusminus" :max="20" align="center" size="normal" className="form-control" width="100%" style="height:2.714rem" :step="1"></vue-numeric-input>
        </b-form-group>
      </b-col> -->

      <!-- Dropdown: Drivetrain Layouts-->
      <!-- <b-col cols="12" md="4">
        <b-form-group label="Drivetrain" label-for="drivetrain">
          <v-select
            v-model="vehicleData.drivetrain"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'drivetrain' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="vehicleData.drivetrain"
            :options="dropAll['drivetrain']"
            label="value"
            :reduce="(val) => val.id"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col> -->

      <!-- Dropdown: Trim -->
      <!-- <b-col cols="12" md="4">
        <b-form-group label="Trim" label-for="trim">
          <v-select
            v-model="vehicleData.trim"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'trim' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="vehicleData.trim"
            :options="trimOptionsVal"
            :reduce="(val) => val.id"
            label="value"
            :clearable="true"
            :disabled="!vehicleData.model > 0"
          >
          </v-select>
        </b-form-group>
      </b-col> -->

      <!-- <b-col cols="12" md="4">
        <b-form-group label="Number of Doors" label-for="numberOfDoors">
          <vue-numeric-input v-model="vehicleData.numberOfDoors" :min="0" controlsType="plusminus" :max="20" align="center" size="normal" className="form-control" width="100%" style="height:2.714rem" :step="1"></vue-numeric-input>
        </b-form-group>
      </b-col> -->

      <!-- Dropdown: Transmmission Type-->
      <!-- <b-col cols="12" md="4">
        <b-form-group label="Transmission" label-for="transmmission">
          <v-select
            v-model="vehicleData.transmmission"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'transmmission' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="vehicleData.transmmission"
            :options="dropAll['transmmission']"
            label="value"
            :reduce="(val) => val.id"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col> -->
    </b-row>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,

    vSelect,
    VueNumericInput,
    ToastificationContent,

    //validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    vehicleData: {
      type: Object,
      required: true,
    },

    getAllDropDown: {
      required: true,
    },

    dropAll: {
      required: true,
    },

    formShow: {
      required: false,
    },
  },

  data() {
    var dropCondition = ['NEW', 'USED'];
    var dropMileageUnit = ['KM', 'MI'];
    var wherefrom = router.currentRoute.name;

    return {
      wherefrom,
      dropdownLoading: false,
      dropCondition,
      dropMileageUnit,
      modelOptionsVal: [],
      trimOptionsVal: [],
      oldCardDisabled: false,
    };
  },

  directives: {
    uppercase: {
      update(element) {
        element.value = element.value.toUpperCase();
      },
    },
  },

  watch: {
    'vehicleData.make': {
      handler: function(id, before) {
        this.makeSelected(this.vehicleData.make);
      },
    },

    'vehicleData.model': {
      handler: function(id, before) {
        if (this.vehicleData.model != null) {
          //  this.msrpClick();
          this.modelSelected(this.vehicleData.model);
        }
      },
    },
  },

  methods: {
    createNewDropdownValue(val) {
      this.dropdownLoading = true;

      switch (val.who) {
        case 'extColour':
          if (this.dropAll['extColour'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveExtColour', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['extColour'].unshift(response.data);
                this.vehicleData.extColour = response.data.id;
                this.toastMessage('Ext Colour');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;
        case 'modelYear':
          if (this.dropAll['modelYear'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveModelYear', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['modelYear'].unshift(response.data);
                this.vehicleData.modelYear = response.data.id;
                this.toastMessage('Model Year');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'intColour':
          if (this.dropAll['intColour'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveIntColour', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['intColour'].unshift(response.data);
                this.vehicleData.intColour = response.data.id;
                this.toastMessage('Interior Colour');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'make':
          if (this.dropAll['make'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveMakes', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['make'].unshift(response.data);
                this.vehicleData.make = response.data.id;
                this.toastMessage('Make');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'body':
          if (this.dropAll['body'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveBodyType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['body'].unshift(response.data);
                this.vehicleData.body = response.data.id;
                this.toastMessage('Body Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'fuel':
          if (this.dropAll['fuel'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveFuelType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['fuel'].unshift(response.data);
                this.vehicleData.fuel = response.data.id;
                this.toastMessage('Fuel Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'model':
          if (this.modelOptionsVal.find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveModel', { value: val.value.toUpperCase().trim(), makeId: this.vehicleData.make }).then((response) => {
              if (response.status == 201) {
                this.modelOptionsVal.unshift(response.data);
                this.vehicleData.model = response.data.id;
                this.toastMessage('Model');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'drivetrain':
          if (this.dropAll['drivetrain'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveDrivetrain', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['drivetrain'].unshift(response.data);
                this.vehicleData.drivetrain = response.data.id;
                this.toastMessage('Drivetrain');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'trim':
          if (this.trimOptionsVal.find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveTrim', { value: val.value.toUpperCase().trim(), modelId: this.vehicleData.model }).then((response) => {
              if (response.status == 201) {
                this.trimOptionsVal.unshift(response.data);
                this.vehicleData.trim = response.data.id;
                this.toastMessage('Trim');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'transmmission':
          if (this.dropAll['transmmission'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveTransmmissionType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['transmmission'].unshift(response.data);
                this.vehicleData.transmmission = response.data.id;
                this.toastMessage('Transmission');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'vinNo':
          if (this.dropAll['vinNo'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveVinNo', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['vinNo'].unshift(response.data);
                this.vehicleData.vinNo = response.data.id;
                this.toastMessage('Vehicle Identification Number');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'origenalMarket':
          if (this.dropAll['origenalMarket'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveOrigenalMarket', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['origenalMarket'].unshift(response.data);
                this.vehicleData.origenalMarket = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpCurrency':
          if (this.dropAll['baseMsrpCurrency'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('inventory/saveBaseMsrpCurrency', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['baseMsrpCurrency'].unshift(response.data);
                this.vehicleData.msrpCurrency = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpValueOptions':
          if (this.msrpValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('inventory/saveMsrpOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.vehicleData.make,
                modelId: this.vehicleData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.msrpValueOptions.unshift(response.data);
                  this.msrpValueOption = response.data.id;
                  this.toastMessage('Msrp Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpPriceOptions':
          if (val.price >= 0) {
            if (this.msrpPriceOptions.find((x) => x.price == val.price) == undefined) {
              store
                .dispatch('inventory/saveMsrpPrice', {
                  price: val.price.trim(),
                  optionId: this.msrpValueOption,
                })
                .then((response) => {
                  if (response.status == 201) {
                    this.msrpPriceOptions.unshift(response.data);
                    this.msrpPriceOption = response.data.id;
                    this.toastMessage('Msrp Options Price');
                    this.dropdownLoading = false;
                  }
                });
            } else {
              this.dropdownLoading = false;
            }
          } else {
            this.dropdownLoading = false;
            this.msrpPriceOption = null;
          }
          break;

        case 'accessioreValueOptions':
          if (this.accessioreValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('inventory/saveAccessioreOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.vehicleData.make,
                modelId: this.vehicleData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.accessioreValueOptions.unshift(response.data);
                  this.accessioreValueOptions.id = response.data.id;
                  this.toastMessage('Accessorie Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;

        default:
          break;
      }
    },

    selectedDropdownValue(val) {
      switch (val.who) {
        case 'extColour':
          this.vehicleData.extColour = this.dropAll['extColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.extColour != undefined && this.vehicleData.extColour != 0) {
            this.vehicleData.extColour = this.dropAll['extColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }

          break;

        case 'modelYear':
          this.vehicleData.modelYear = this.dropAll['modelYear'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];

          if (this.vehicleData.modelYear != undefined && this.vehicleData.modelYear != 0) {
            this.vehicleData.modelYear = this.dropAll['modelYear'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'intColour':
          this.vehicleData.intColour = this.dropAll['intColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.intColour != undefined && this.vehicleData.intColour != 0) {
            this.vehicleData.intColour = this.dropAll['intColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }

          break;

        case 'make':
          this.vehicleData.make = this.dropAll['make'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.make != undefined && this.vehicleData.make != 0) {
            this.vehicleData.make = this.dropAll['make'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'body':
          this.vehicleData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.body != undefined && this.vehicleData.body != 0) {
            this.vehicleData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'fuel':
          this.vehicleData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.fuel != undefined && this.vehicleData.fuel != 0) {
            this.vehicleData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'model':
          this.vehicleData.model = this.modelOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.model != undefined && this.vehicleData.model != 0) {
            this.vehicleData.model = this.modelOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }

          break;

        case 'drivetrain':
          this.vehicleData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.drivetrain != undefined && this.vehicleData.drivetrain != 0) {
            this.vehicleData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'trim':
          this.vehicleData.trim = this.trimOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.trim != undefined && this.vehicleData.trim != 0) {
            this.vehicleData.trim = this.trimOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'transmmission':
          this.vehicleData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.transmmission != undefined && this.vehicleData.transmmission != 0) {
            this.vehicleData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'vinNo':
          this.vehicleData.vinNo = this.dropAll['vinNo'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.vinNo != undefined && this.vehicleData.vinNo != 0) {
            this.vehicleData.vinNo = this.dropAll['vinNo'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'origenalMarket':
          this.vehicleData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.origenalMarket != undefined && this.vehicleData.origenalMarket != 0) {
            this.vehicleData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'msrpCurrency':
          this.vehicleData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.vehicleData.msrpCurrency != undefined && this.vehicleData.msrpCurrency != 0) {
            this.vehicleData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'msrpValueOptions':
          this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          if (this.msrpValueOption != undefined && this.msrpValueOption != 0) {
            this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0].id;
          }

          this.msrpMakeSelected(this.msrpValueOption);

          break;

        case 'msrpPriceOptions':
          this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0];

          if (this.msrpPriceOption != undefined && this.msrpPriceOption != 0) {
            this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0].id;
          }

          break;

        case 'accessioreValueOptions':
          this.accessioreValueOptions.id = this.accessioreValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          break;

        default:
          break;
      }
    },

    makeSelected(k) {
      if (k != null || k != undefined) {
        this.dropdownLoading = true;
        store
          .dispatch('inventory/getModelByMake', k)
          .then((response) => {
            if (response.data[0] != undefined) {
              this.modelOptionsVal = response.data;
              this.dropAll.model = response.data;
            } else {
              this.modelOptionsVal = [];
            }

            this.dropdownLoading = false;
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Model list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    modelSelected(k) {
      /*  if (c == 1) {
        this.vehicleData.trim = '';
        this.trimOptionsVal = [];
      }*/

      if (k != null || k != undefined) {
        this.dropdownLoading = true;

        store
          .dispatch('inventory/getTrimByModel', k)
          .then((response) => {
            this.$emit('update:formShow', false);
            if (response.data[0] != undefined) {
              this.trimOptionsVal = response.data;
            } else {
              this.trimOptionsVal = [];
            }

            this.dropdownLoading = false;
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Trim list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    vinNoCheck(k) {
      if (k != '') {
        this.$emit('update:formShow', true);
        store
          .dispatch('inventory/vinNoCheck', { vinNo: k })
          .then((response) => {
            this.$emit('update:formShow', false);
            if (response.data.res == 1) {
              //start

              this.$swal({
                //   title: 'Information',
                text: 'A vehicle with this VIN number has been created previously. Do you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  this.getAllDropDown();

                  //YES
                  this.vehicleData.condition = response.data.data.condition;
                  this.vehicleData.modelYear = response.data.data.modelYear;
                  this.vehicleData.make = response.data.data.make;
                  this.vehicleData.stockNumber = response.data.data.stockNumber;
                  this.vehicleData.model = response.data.data.model;

                  this.vehicleData.body = response.data.data.body;
                  this.vehicleData.numberOfDoors = response.data.data.numberOfDoors;
                  this.vehicleData.engineCode = response.data.data.engineCode;

                  this.vehicleData.numberOfCylinders = response.data.data.numberOfCylinders;
                  this.vehicleData.seatingCapacity = response.data.data.seatingCapacity;
                  this.vehicleData.curbWeight = response.data.data.curbWeight;
                  this.vehicleData.frontTyres = response.data.data.frontTyres;
                  this.vehicleData.rearTyres = response.data.data.rearTyres;

                  this.vehicleData.maximumHorsepower = response.data.data.maximumHorsepower;
                  this.vehicleData.engineDisplacement = response.data.data.engineDisplacement;
                  this.vehicleData.wheelbase = response.data.data.wheelbase;
                  this.vehicleData.fuel = response.data.data.fuel;
                  this.vehicleData.transmmission = response.data.data.transmmission;

                  this.oldCardDisabled = true;
                } else {
                  this.vehicleData.vinNo = '';
                  this.vehicleData.condition = null;
                  this.vehicleData.modelYear = null;
                  this.vehicleData.make = null;
                  this.vehicleData.stockNumber = null;
                  this.vehicleData.model = null;
                  this.oldCardDisabled = false;
                }
              });

              //finish
            } else if (response.data.res == 0) {
              this.getAllDropDown();

              this.vehicleData.condition = 'USED';
              this.vehicleData.modelYear = response.data.data.modelYear;
              this.vehicleData.make = response.data.data.make;
              this.vehicleData.stockNumber = null;
              this.vehicleData.model = response.data.data.model;
              this.vehicleData.body = response.data.data.body;
              this.vehicleData.numberOfDoors = response.data.data.numberOfDoors;
              this.vehicleData.engineCode = response.data.data.engineCode;

              this.vehicleData.numberOfCylinders = response.data.data.numberOfCylinders;
              this.vehicleData.seatingCapacity = response.data.data.seatingCapacity;
              this.vehicleData.curbWeight = response.data.data.curbWeight;
              this.vehicleData.frontTyres = response.data.data.frontTyres;
              this.vehicleData.rearTyres = response.data.data.rearTyres;

              this.vehicleData.maximumHorsepower = response.data.data.maximumHorsepower;
              this.vehicleData.engineDisplacement = response.data.data.engineDisplacement;
              this.vehicleData.wheelbase = response.data.data.wheelbase;
              this.vehicleData.fuel = response.data.data.fuel;
              this.vehicleData.transmmission = response.data.data.transmmission;

              //4T1BF1FK7GU559325

              // if (response.data.data.modelYear != null && response.data.data.make != null && response.data.data.model != null) {
              //   this.oldCardDisabled = false;
              // } else {
              this.oldCardDisabled = false;
              // }
              // this.oldCardDisabled = true;

              // this.vehicleData.condition = null;
              // this.vehicleData.modelYear = null;
              // this.vehicleData.make = null;
              // this.vehicleData.stockNumber = null;
              // this.vehicleData.model = null;
              // this.oldCardDisabled = false;
            } else {
              this.$swal({
                //   title: 'Information',
                text: 'A vehicle with this VIN number has been created previously and processing continues. Do you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, go to that vehicle',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  //YES

                  this.$router.push({ name: 'inventory-view', params: { id: response.data.data.id } });
                } else {
                  this.vehicleData.vinNo = '';
                }
              });
            }
          })
          .catch((e) => {
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching check control',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    modelClear() {
      this.vehicleData.model = null;
      this.vehicleData.trim = null;
    },
    trimClear() {
      this.vehicleData.trim = null;
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>
